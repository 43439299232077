<template>
  <Transition name="page" mode="out-in">
    <IconComponent :="{ ...props, active }" />
  </Transition>
</template>

<script setup lang="ts">
// defines
const props = defineProps<{ name: string; active?: boolean }>()

// data
const IconComponent = computed(() => {
  const [first, second] = props.name.split('/')
  if (!second) return defineAsyncComponent(() => import(`./icons/${first}.vue`))
  return defineAsyncComponent(() => import(`./icons/editor/${second}.vue`))
})
</script>
